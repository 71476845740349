/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function DefaultFooter() {
  return (
    <>
      <footer className="footer footer-default">
        <Row className="footerRow">
          <Col className="footerLeft" lg="6" md="12" sm="12">
            <nav>
              <ul>
                <li>
                  <a target="_blank">
                    The Law Offices of Avrum J. Rosen, PLLC{" "}
                  </a>
                  <>&bull;</>
                  <a style={{ color: "black" }} href="tel:+1-631-423-8527">
                    {" "}
                    <span>631-423-8527</span>
                  </a>{" "}
                </li>
              </ul>
            </nav>
          </Col>
          <Col className="footerRight" lg="6" md="12" sm="12">
            <div className="copyright" id="copyright">
              © {new Date().getFullYear()}, Powered by{" "}
              <a href="https://www.launchsitellc.com" target="_blank">
                Launch Site LLC
              </a>
            </div>
          </Col>
        </Row>
      </footer>
    </>
  );
}

export default DefaultFooter;
