import React from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";
import GoogleMapsSection from "views/index-sections/GoogleMapsSection";

import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import KnowlegeIsPower from "views/index-sections/KnowlegeIsPower";
import ServingNewYork from "views/index-sections/ServingNewYork";
import Background from "views/index-sections/Background";
import SvgContainer from "views/index-sections/SvgContainer";

function AboutOurFirm() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          headerTitle={"About Us"}
          headerImage={
            "url(" + require("assets/img/aboutUsHeaderDark.png") + ")"
          }
          svgBottom={"url(" + require("assets/img/whiteVector7.svg") + ")"}
        />
        <KnowlegeIsPower />

        <SvgContainer
          /*svgTop={"url(" + require("assets/img/whiteVector5.svg") + ")"}*/
          svgTop={"url(" + require("assets/img/whiteVector3.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/boatsBackground.png") + ")"
          }
          sectionContent={<ServingNewYork />}
        />

        <Background />

        <SvgContainer
          className="google-maps-section"
          //initialHeight={"107vh"}
          svgTop={"url(" + require("assets/img/whiteVector3.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/peopleInOffice.png") + ")"
          }
          sectionContent={
            <GoogleMapsSection
              header={
                <>
                  <h2 className="title title-main">Contact Us</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </>
              }
            />
          }
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/aboutUsBottom.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default AboutOurFirm;
