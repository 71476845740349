import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class Background extends Component {
  state = {};
  render() {
    return (
      <div className="section section-about-us section-story-overview">
        <Container>
          <Row>
            <Col md="8">
              <h2>Our History</h2>{" "}
              <span
                style={{ marginBottom: "5em", float: "left" }}
                className="pp-seperator-line"
              ></span>
            </Col>
          </Row>
          <Row>
            <Col md="6">
              <p style={{ fontWeight: 300 }}>
                The Law Offices of Avrum J. Rosen, PLLC is a boutique practice
                located in Huntington, Long Island. Our firm was founded by
                Avrum Rosen, and includes Deborah Dobbin, Nico Pizzo and Alex
                Tsionis as associates. We focus on all aspects of bankruptcy law
                and creditors' rights, as well as commercial and residential
                real estate law.
              </p>
              <p style={{ fontWeight: 300 }}>
                We're experienced in representing Chapter 11 debtors engaged in
                operating small to mid-size companies, and have successfully
                reorganized several businesses in Long Island and New York City.
              </p>
              <p style={{ fontWeight: 300 }}>
                We also have extensive experience representing individuals in
                health care bankruptcies, Chapter 7 Trustees, Operating Trustees
                and Creditors' Committees. Plaintiffs and defendants in various
                bankruptcy proceedings utilize our firm's services.
              </p>
              <p style={{ fontWeight: 300 }}>
                We're also experienced in commercial and residential real estate
                law.
              </p>
            </Col>
            <Col md="6">
              <div
                className="image-container image-right imageRightPadding"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/longIslandSunset.png") + ")",
                }}
              ></div>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default Background;
