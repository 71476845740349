import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class FieldExperts extends Component {
  state = {};
  render() {
    return (
      <div className="section">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="10">
              <h2 style={{ marginBottom: "2em" }} className="title title-main">
                Trusted and Experienced in Bankruptcy and Real Estate Law
              </h2>
              <img
                alt="martindale-hubbel 2021"
                src={require("assets/img/martindale-hubbel-distinguished.png")}
                style={{ width: "250px", marginBottom: "2em" }}
              ></img>
              <img
                alt="martindale-hubbel 2021"
                src={require("assets/img/martindale-hubbel-preeminent.png")}
                style={{ width: "250px", marginBottom: "2em" }}
              ></img>
              <h5 style={{ color: "black" }} className="description">
                Our experienced, knowledgeable attorneys understand bankruptcy
                law and the various options at your disposal under the law. We
                will thoroughly explain the process and assess whether or not
                bankruptcy may be right for you.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default FieldExperts;
