import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import SliderComponent from "components/Misc/SliderComponent.jsx";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";

import OurClients from "views/index-sections/OurClients";

import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import UsefulLinks from "views/index-sections/UsefulLinks";
import SvgContainer from "views/index-sections/SvgContainer";

function CurrentClients() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          headerTitle={"Current Clients"}
          headerImage={"url(" + require("assets/img/manWriting.jpg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector2.svg") + ")"}
        />{" "}
        <OurClients />
        <SvgContainer
          className="useful-links"
          /*svgTop={"url(" + require("assets/img/whiteVector5.svg") + ")"}*/
          svgTop={"url(" + require("assets/img/whiteVector3.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/workSpaceBackground.png") + ")"
          }
          sectionContent={<UsefulLinks />}
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/manhattanFooter-compressor.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default CurrentClients;
