import React, { Component } from "react";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import GoogleMaps from "components/Misc/GoogleMaps.jsx";

class GoogleMapsSection extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto " md="8">
            {this.props.header}
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
            <GoogleMaps />
          </Col>
          <Col className="ml-auto mr-auto" md="6">
            <h3 className="title">Address:</h3>
            <h4>38 New Street Huntington, NY 11743</h4>
            <h3 className="title">Phone:</h3>
            <a style={{ color: "black" }} href="tel:+1-631-423-8527">
              {" "}
              <h4>631-423-8527</h4>
            </a>

            <h3 className="title">Fax:</h3>
            <h4>631-423-4536</h4>
          </Col>
        </Row>

        <div className="separator separator-primary"></div>
      </Container>
    );
  }
}

export default GoogleMapsSection;
