import React from "react";
import Slider from "react-slick";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

import SliderComponent from "components/Misc/SliderComponent.jsx";

const team = [
  {
    header: "Patrick Benson",
    subHeader: "Google Reviews",
    rating: 5,
    description:
      "Alex was fantastic, efficient and swift.  He fully prepared me to deal with my issue and resolved it quicker than I ever anticipated.  Kelly and I can't say enough positive things about Alex.  Top tier professional with optimal results."
  },
  {
    header: "Jr Thomas",
    subHeader: "Google Reviews",
    rating: 5,
    description:
      "If you are looking for the best look no further. Alex Tsionis is not only a great attorney but a kind and compassionate person. His vast Legal knowledge and expertise was clear from the start. Alex always was available supporting any questions or concerns I might of had even after business hours. I can't thank him enough for all of his great work. He delivers above expectation.  This is simply a great firm to be represented by ! 5 stars are not enough for Alex & Fred !!"
  },
  {
    header: "Salvatore Cassandro",
    subHeader: "Google Reviews",
    rating: 5,
    description:
      "Amazing Law Firm. Alex is one of the best lawyers in New York. His level of expertise and professionalism was appreciated. I was able to call Alex whenever  was needed and he was there to support me through my legal process. I would definitely recommend Alex of the Rosen and Kantrow Firm for all your legal needs. He treats you more like family then a client. Great Overall Experience, Thank You Alex !!"
  },
  {
    header: "Cecilia Patritti",
    subHeader: "Google Reviews",
    rating: 5,
    description:
      "Alex Tsionis and the Rosen Kantrow firm represented  me on my real estate transaction. Alex was very efficient and informative keeping me apprised of my case every step of the way. I highly recommend Alex for all of your legal needs. He went above and beyond his call of duty. I highly recommend!"
  },
  {
    header: "Joseph Devito",
    subHeader: "Google Reviews",
    rating: 5,
    description:
      "I have dealt with Alex Tsionis of Rosen and Kantrow on numerous real estate files, and I have also referred several bankruptcy clients as well. Alex is very responsive and has great attention to detail. Some files were very time intensive and required rare cases of default and he was able to successfully protect my clients rights while persevering toward a closing. Unlike many real estate attorneys who appear to never have time to talk to you Alex is very communicative utilizing modern modes of communication to expedite matters and keep things moving along.  Highly recommend Rosen and Kantrow for all of your real estate and bankruptcy needs."
  }
];

function Testimonials() {
  var settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1
  };
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <>
      <Container>
        <Row>
          <Col
            style={{ marginBottom: "2em" }}
            className="ml-auto mr-auto text-center"
            md="8"
          >
            <h2 className="title title-main">Client Testimonials</h2>
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
            <h5 style={{ color: "black" }} className="description">
              See why our clients love us.
            </h5>
          </Col>
        </Row>
        <Row>
          <Slider style={{ margin: "auto", width: "90%" }} {...settings}>
            {team.map(item => (
              <SliderComponent
                headerText={item.header}
                subText={item.subHeader}
                description={item.description}
                rating={item.rating}
              />
            ))}
          </Slider>
        </Row>
      </Container>
    </>
  );
}

export default Testimonials;
