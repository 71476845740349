import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import SliderComponent from "components/Misc/SliderComponent.jsx";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";

import Decisions from "views/index-sections/Decisions";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import SvgContainer from "views/index-sections/SvgContainer";

function ReportedDecisions() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          headerTitle={"Reported Decisions"}
          headerImage={
            "url(" + require("assets/img/reportedDecisions.jpg") + ")"
          }
          svgBottom={"url(" + require("assets/img/grayVector.svg") + ")"}
        />{" "}
        <div style={{ padding: "30px" }} className="section-tabs">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="10">
                <h2 className="title title-main">Our Reported Decisions</h2>
                <span
                  style={{ marginBottom: "5em" }}
                  className="pp-seperator-line"
                ></span>
              </Col>
            </Row>
          </Container>
        </div>
        <SvgContainer
          //initialHeight={"100vh"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/courtHouseBackground.png") + ")"
          }
          sectionContent={<Decisions />}
          className={"reported-decisions"}
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/chandellierFooter.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default ReportedDecisions;
