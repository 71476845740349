import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function HereToHelp() {
  return (
    <>
      <div className="section">
        <Container className="text-center">
          <Row className="justify-content-md-center">
            <Col lg="8" md="12">
              <h2 className="title title-main">We're here to help.</h2>
              <span
                style={{ marginBottom: "5em" }}
                className="pp-seperator-line"
              ></span>

              <h5 style={{ color: "black" }} className="description">
                Please provide us with some details, and we'll reach out to you
                as soon as we can.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default HereToHelp;
