import React from "react";
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

import { Link, animateScroll as scroll } from "react-scroll";

function LandingPageHeader(props) {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header">
        <div
          className="page-header-image"
          style={{
            backgroundImage: "url(" + require("assets/img/bg12.jpg") + ")"
          }}
          ref={pageHeader}
        ></div>
        {props.svgBottom ? (
          <div
            className="svgBottom"
            style={{
              background: props.svgBottom,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom"
            }}
          />
        ) : null}
        <div className="content-center">
          <Container>
            <h1 className="title header-title">
              Passionately representing debtors, creditors and trustees for over
              30 years.
            </h1>

            <Row>
              <Col className="text-center ml-auto mr-auto" lg="6" md="8">
                <Link
                  to="contactUs"
                  spy={true}
                  smooth={true}
                  //offset={50}
                  duration={700}
                >
                  <div className="send-button">
                    <Button
                      block
                      className="btn-round"
                      color="info"
                      onClick={e => e.preventDefault()}
                      size="lg"
                    >
                      Schedule Your Free Consultation
                    </Button>
                  </div>{" "}
                </Link>
              </Col>
            </Row>
          </Container>
        </div>
      </div>
    </>
  );
}

export default LandingPageHeader;
