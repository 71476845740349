import React, { Component } from "react";
import { withRouter } from "react-router-dom";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { Link, animateScroll as scroll } from "react-scroll";

class About extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    return (
      <div className="section section-about-us">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title title-main">
                Experience Makes All The Difference
              </h2>

              <span
                style={{ marginBottom: "2em" }}
                className="pp-seperator-line"
              ></span>

              <img
                alt="martindale-hubbel 2021"
                src={require("assets/img/martindale-hubbel-distinguished.png")}
                style={{ width: "250px", marginBottom: "2em" }}
              ></img>
              <img
                alt="martindale-hubbel 2021"
                src={require("assets/img/martindale-hubbel-preeminent.png")}
                style={{ width: "250px", marginBottom: "2em" }}
              ></img>
            </Col>
          </Row>
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="4" md="4">
              <div style={{ marginBottom: "4em" }} className="team-player">
                <i
                  style={{ fontSize: "30px" }}
                  className="now-ui-icons business_bank"
                ></i>
                <h4 style={{ paddingTop: "0px" }} className="title">
                  Practicing Law Over 30 Years
                </h4>
                <p>We've been serving New Yorkers for over 30 years.</p>
              </div>
            </Col>
            <Col className="text-center ml-auto mr-auto" lg="4" md="4">
              <div style={{ marginBottom: "4em" }} className="team-player">
                <i
                  style={{ fontSize: "30px" }}
                  className="now-ui-icons sport_trophy"
                ></i>
                <h4 style={{ paddingTop: "0px" }} className="title">
                  Successfully Advocating For Our Clients
                </h4>
                <p>
                  We've successfully reorganized several businesses across Long
                  Island and New York City.
                </p>
              </div>
            </Col>
            <Col className="text-center ml-auto mr-auto" lg="4" md="4">
              <div style={{ marginBottom: "4em" }} className="team-player">
                <i
                  style={{ fontSize: "30px" }}
                  className="now-ui-icons sport_user-run"
                ></i>
                <h4 style={{ paddingTop: "0px" }} className="title">
                  Dedicated To Delivering Quality Results
                </h4>
                <p>
                  We'll do everything in our power to help you succeed, every
                  step of the way.
                </p>
              </div>
            </Col>
          </Row>
          <Row>
            <Col className="text-center ml-auto mr-auto" lg="6" md="8">
              <div className="send-button">
                <Button
                  block
                  className="btn-round"
                  color="info"
                  onClick={() => this.props.routeChange("/about-our-firm")}
                  size="lg"
                >
                  Learn About Our Firm
                </Button>
              </div>
            </Col>
          </Row>
          <div className="separator separator-primary"></div>
        </Container>
      </div>
    );
  }
}

export default withRouter(About);
