import React, { Component } from "react";
// Import reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

class AuctionSection extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container>
          <div
            style={{
              width: "100%",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Row style={{ justifyContent: "center" }}>
              <Col className="pressText" md="9">
                <hr style={{ marginTop: "3em", marginBottom: "3em" }} />

                <div>
                  <h4 style={{ fontWeight: "bold", margin: "0px" }}>
                    Auction:{" "}
                  </h4>
                  <h4 style={{ textAlign: "left", marginTop: "1em" }}>
                    {this.props.title}
                  </h4>
                </div>

                <div>
                  <h4 style={{ fontWeight: "bold", margin: "0px" }}>
                    Auction Date:{" "}
                  </h4>
                  <h4 style={{ marginTop: "1em" }}>{this.props.date}</h4>
                </div>

                <div>
                  <Row style={{ width: "80%" }}>
                    <Col md="6" sm="12">
                      {" "}
                      <div
                        style={{
                          width: "260px",
                        }}
                        className="send-button"
                      >
                        <a
                          className="clean-link"
                          href={this.props.noticeOfAuction}
                          download
                          style={{
                            width: "260px",
                            height: "100%",
                            textAlign: "center",
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            size="lg"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            Notice of Auction
                            <i
                              style={{ marginLeft: "1em" }}
                              class="fas fa-download"
                            ></i>
                          </Button>{" "}
                        </a>
                      </div>
                    </Col>
                    <Col md="6" sm="12">
                      {" "}
                      <div style={{ width: "260px" }} className="send-button">
                        <a
                          className="clean-link"
                          href={this.props.termsOfSale}
                          download
                          style={{
                            width: "260px",
                            height: "100%",
                            textAlign: "center",
                            display: "inline-block",
                            position: "relative",
                          }}
                        >
                          <Button
                            block
                            className="btn-round"
                            color="info"
                            size="lg"
                            style={{
                              display: "flex",
                              justifyContent: "center",
                              alignItems: "center",
                              textAlign: "center",
                            }}
                          >
                            Terms of Sale
                            <i
                              style={{ marginLeft: "1em" }}
                              class="fas fa-download"
                            ></i>
                          </Button>{" "}
                        </a>
                      </div>
                    </Col>
                  </Row>
                </div>
                <hr style={{ marginTop: "3em", marginBottom: "3em" }} />
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default AuctionSection;
