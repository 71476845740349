import React, { Component } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

import DecisionCard from "./DecisionCard.jsx";

class Decisions extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Container className="decisionsStyle">
        <Row>
          <Col className="ml-auto mr-auto text-center" lg="6" md="12">
            <DecisionCard
              case="
In Re Peter Bogdanovich & Louise Hoogstratten Bogdanovich, Debtors.gerald Schneiderman, Plaintiff,aly Spencer, and Barry Spencer, Plaintiffs-appellees, v. Peter Bogdanovich & Louise Hoogstratten Bogdanovich, Debtors-appellants, 292 F.3d 104 (2d Cir. 2002)"
              court="US Court of Appeals for the Second Circuit - 292 F.3d 104 (2d
                  Cir. 2002)"
              argued="December 7, 2001"
              decided="May 15, 2002"
              link="https://law.justia.com/cases/federal/appellate-courts/F3/292/104/642372/"
            />
          </Col>
          <Col className="ml-auto mr-auto text-center" lg="6" md="12">
            <DecisionCard
              case="Karamvir Dahiya & Dahiya Law Offices LLC, Karamvir Dahiya, Plaintiffs-Appellants, v. Debra Kramer, as Trustee of the Estate of Shahara Khan, Defendant-Appellee"
              court="US Court of Appeals for the Second Circuit - 292 F.3d 104 (2d
                  Cir. 2002)"
              argued="February 5, 2015"
              decided="February 5, 2015"
              link="https://232d24ed-d912-4ece-b60f-4cc53f78b63e.filesusr.com/ugd/1265d4_51022f0c37e3429d9d8e17af94d827e1.pdf"
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

export default Decisions;
