import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";
import GoogleMapsSection from "views/index-sections/GoogleMapsSection";

import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import SvgContainer from "views/index-sections/SvgContainer";
import LawyerBio from "views/index-sections/LawyerBio";

function OurTeam() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          headerTitle={"Our Attorneys"}
          headerImage={
            "url(" + require("assets/img/atorneyHeaderDark.png") + ")"
          }
          svgBottom={"url(" + require("assets/img/grayVector.svg") + ")"}
        />
        <SvgContainer
          svgBottom={"url(" + require("assets/img/whiteVector7.svg") + ")"}
          sectionContent={
            <LawyerBio
              bgColor={"#eeeeee"}
              lawyerId="avrum-rosen"
              src={require("assets/img/avrum-scaled.png")}
              name={"Avrum J. Rosen"}
              title={"Member"}
              practiceAreas={
                "Bankruptcy Law; Litigation; Real Estate Law; Commercial Real Estate."
              }
              barAdmissions="Admitted to bar, 1984, New York; 1985, U.S. District Court,
                  Southern, Northern and Eastern Districts of New York; 2001,
                  U.S. Court of Appeals, Second Circuit."
              education="State University of New York at Old Westbury (B.A., 1976);
                  Hofstra University (J.D., 1984). Associate Editor, Hofstra Law
                  Review, 1983-1984. Member: Suffolk County Bar Association."
            />
          }
        />{" "}
        <SvgContainer
          svgBottom={"url(" + require("assets/img/gray4.svg") + ")"}
          sectionContent={
            <LawyerBio
              lawyerId="deborah-dobbin"
              bgColor={"#ffffff"}
              src={require("assets/img/deborah-scaled.png")}
              name={"Deborah L. Dobbin"}
              title={"Associate"}
              practiceAreas={"Bankruptcy Law; Litigation"}
              barAdmissions="Admitted to bar, 1996, New York and U.S. District Court,
                  Eastern and Southern Districts of New York; US Court of
                  Appeals, Second Circuit and US Supreme Court."
              education="Tufts University (B.A., cum laude, 1992); Brooklyn Law School
                  (J.D., with honors, 1995). Law Clerk to Hon. Stan Bernstein,
                  U.S. Bankruptcy Judge, Eastern District of New York. Formerly
                  with Sanders, Gutman & Brodie, P.C. Member, Brooklyn Bar
                  Association, 1995-1999."
            />
          }
        />{" "}
        <SvgContainer
          svgBottom={"url(" + require("assets/img/whiteVector7.svg") + ")"}
          sectionContent={
            <LawyerBio
              lawyerId="nico-pizzo"
              bgColor={"#eeeeee"}
              src={require("assets/img/nico-scaled.png")}
              name={"Nico G. Pizzo"}
              title={"Associate"}
              practiceAreas={"Bankruptcy Law; Litigation"}
              barAdmissions="Admitted to bar, 2018, New York and U.S. District Court,
                  Eastern District of New York."
              education="State University of New York at Stony Brook (B.A.) Touro
                  College Jacob D. Fuchsberg Law Center (J.D. 2018)."
            />
          }
        />{" "}
        <LawyerBio
          lawyerId="alex-tsionis"
          bgColor={"#ffffff"}
          src={require("assets/img/alex-scaled.png")}
          name={"Alex E. Tsionis"}
          title={"Associate"}
          practiceAreas={
            "Bankruptcy Law; Litigation; Real Estate Law; Commercial Real Estate."
          }
          barAdmissions="Admitted to bar, 2017, New York, New Jersey and U.S. District
                  Court, Eastern District of New York, Northern District of New
                  York, Southern District of New York, Western District of New
                  York, United States Court of Appeals, Second Circuit, U.S.
                  District Court, District of New Jersey."
          education="Saint Peter's University (B.A. Manga Cum Laude), Saint Peter's
                  University (M.B.A. Finance), Touro College Jacob D. Fuchsberg
                  Law Center (J.D. 2016, Cum Laude)."
        />
        <SvgContainer
          className="google-maps-section"
          //initialHeight={"107vh"}
          svgTop={"url(" + require("assets/img/whiteVector3.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/peopleInOffice.png") + ")"
          }
          //minHeight={"93vh"}
          sectionContent={<GoogleMapsSection />}
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/aboutUsBottom.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default OurTeam;
