import React from "react";
import { withRouter } from "react-router-dom";

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

// core components

function Specialties(props) {
  const [iconPills, setIconPills] = React.useState("1");
  const [pills, setPills] = React.useState("1");
  return (
    <div style={{ padding: "2em" }}>
      <Container className="expertiseContainer">
        <Row>
          <Col
            style={{ marginBottom: "2em" }}
            className="ml-auto mr-auto text-center"
            md="8"
          >
            <h2 className="title title-main">Our Areas of Expertise</h2>
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
            <h5 style={{ color: "black" }} className="description">
              Everyone's situation is different. We'll help tailor a plan that's
              right for you.
            </h5>
          </Col>
        </Row>
        <Row>
          <Col className="ml-auto mr-auto" md="3" xl="3">
            <Card onClick={() => props.routeChange("/areas-of-expertise")}>
              <CardHeader className="text-center">
                <h5 className="title">Chapter 7 Bankruptcy</h5>
                <hr style={{ textAlign: "center", width: "75%" }} />
              </CardHeader>
              <CardBody>
                <p>
                  Take a positive step toward restoring your financial
                  independence.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="3" xl="3">
            <Card onClick={() => props.routeChange("/areas-of-expertise")}>
              <CardHeader className="text-center">
                <h5 className="title">Chapter 11 Bankruptcy</h5>
                <hr style={{ textAlign: "center", width: "75%" }} />
              </CardHeader>
              <CardBody>
                <p>
                  Restructure your debts into a more manageable plan for
                  repayment.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="3" xl="3">
            <Card onClick={() => props.routeChange("/areas-of-expertise")}>
              <CardHeader className="text-center">
                <h5 className="title">Chapter 13 Bankruptcy</h5>
                <hr style={{ textAlign: "center", width: "75%" }} />
              </CardHeader>
              <CardBody>
                <p>
                  Restructure your debts into a more manageable plan for
                  repayment.
                </p>
              </CardBody>
            </Card>
          </Col>
          <Col className="ml-auto mr-auto" md="3" xl="3">
            <Card onClick={() => props.routeChange("/areas-of-expertise")}>
              <CardHeader className="text-center">
                <h5 className="title">Real Estate Law</h5>
                <hr style={{ textAlign: "center", width: "75%" }} />
              </CardHeader>
              <CardBody>
                <p>
                  For homeowners, renters, landlords, home buyers and home
                  sellers.
                </p>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row style={{ marginTop: "2em" }}>
          <Col className="text-center ml-auto mr-auto" lg="6" md="8">
            <div className="send-button">
              <Button
                block
                className="btn-round"
                color="info"
                onClick={() => props.routeChange("/areas-of-expertise")}
                size="lg"
              >
                Learn More
              </Button>
            </div>
          </Col>
        </Row>
      </Container>
    </div>
  );
}

export default withRouter(Specialties);
