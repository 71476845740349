/* eslint react/no-multi-comp: 0, react/prop-types: 0 */

import React, { useState } from "react";
import {
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Input,
  Label,
  Form,
  FormGroup,
} from "reactstrap";

const CovidModal = (props) => {
  const { buttonLabel, className } = props;
  const [modal, setModal] = useState(true);
  const [backdrop, setBackdrop] = useState(true);
  const [keyboard, setKeyboard] = useState(true);

  const toggle = () => setModal(!modal);

  const changeBackdrop = (e) => {
    let value = e.target.value;
    if (value !== "static") {
      value = JSON.parse(value);
    }
    setBackdrop(value);
  };

  const changeKeyboard = (e) => {
    setKeyboard(e.currentTarget.checked);
  };

  return (
    <div>
      <Modal
        isOpen={modal}
        toggle={toggle}
        className={className}
        backdrop={backdrop}
        keyboard={keyboard}
      >
        <ModalHeader toggle={toggle}>
          The Law Offices of Avrum J. Rosen, PLLC<br></br>
          COVID-19: Updates
        </ModalHeader>

        <ModalBody>
          <hr></hr>
          <h3>Hello! </h3>
          <p>
            The Law Offices of Avrum J. Rosen, PLLC is open during this
            difficult period of national health and economic crisis.
          </p>{" "}
          <p>
            In observance of Local, State and Federal guidelines, we can consult
            with and represent you or your business as to any kind of debt
            relief, in a manner that allows you to feel safe, comfortable and
            well advised.
          </p>
          <p>
            If you own a small or medium sized business, we may be able to help
            under the new Small Business Reorganization Act, which may be a more
            affordable option than a traditional Chapter 11.
          </p>
          <p>
            If you would like to schedule a free consultation, please contact us
            at the firm’s phone number (631-423-8527). Consultations can be
            conducted by telephone or through video conferencing.
          </p>
          <p>Thank you and stay safe,</p>
          <p>The Law Offices of Avrum J. Rosen, PLLC</p>
        </ModalBody>
        <ModalFooter>
          <a style={{ color: "white" }} href="tel:+1-631-423-8527">
            {" "}
            <Button style={{ backgroundColor: "#2CA8FF" }}> Contact Us</Button>
          </a>
          <Button color="secondary" onClick={toggle}>
            Continue To Website
          </Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default CovidModal;
