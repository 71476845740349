import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";

class LawyerBio extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          id={this.props.lawyerId}
          style={{ visibility: "hidden", height: "0px" }}
        ></div>
        <section
          style={{
            backgroundColor: this.props.bgColor,
            paddingTop: "10em",
            paddingBottom: "10em",
          }}
        >
          <Container style={{ paddingBottom: "0px" }}>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <div className="team-player">
                  <img
                    alt="..."
                    className="rounded-circle img-fluid img-raised"
                    src={this.props.src}
                    style={{
                      borderStyle: "solid",
                      borderWidth: "3px",
                      borderColor: "#2CA8FF",
                      maxWidth: "200px",
                    }}
                  ></img>
                  <h4 style={{ paddingTop: "0px" }} className="title">
                    {this.props.name}
                  </h4>
                  <p className="category text-info"> {this.props.title}</p>
                </div>
              </Col>
            </Row>
            <Row>
              <Col className="text-center ml-auto mr-auto" lg="4" md="4">
                <div className="team-player">
                  <i
                    style={{ fontSize: "30px" }}
                    className="now-ui-icons business_bank"
                  ></i>
                  <h4 style={{ paddingTop: "0px" }} className="title">
                    Practice Areas
                  </h4>
                  <p style={{ fontSize: ".9em" }}>{this.props.practiceAreas}</p>
                </div>
              </Col>
              <Col className="text-center ml-auto mr-auto" lg="4" md="4">
                <div className="team-player">
                  <i
                    style={{ fontSize: "30px" }}
                    className="now-ui-icons business_badge"
                  ></i>

                  <h4 style={{ paddingTop: "0px" }} className="title">
                    Bar Admissions
                  </h4>
                  <p style={{ fontSize: ".9em" }}>{this.props.barAdmissions}</p>
                </div>
              </Col>
              <Col className="text-center ml-auto mr-auto" lg="4" md="4">
                <div className="team-player">
                  <i
                    style={{ fontSize: "30px" }}
                    className="now-ui-icons education_hat"
                  ></i>

                  <h4 style={{ paddingTop: "0px" }} className="title">
                    Education
                  </h4>
                  <p style={{ fontSize: ".9em" }}>{this.props.education}</p>
                </div>
              </Col>
            </Row>
          </Container>
        </section>
      </>
    );
  }
}

export default LawyerBio;
