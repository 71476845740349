import React, { Component } from "react";
// reactstrap components
import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
} from "reactstrap";

import MaskedFormControl from "react-bootstrap-maskedinput";
import ContactFeedback from "./ContactReceived";
import ReCAPTCHA from "react-google-recaptcha";

// core components

class ContactUs extends Component {
  constructor() {
    super();
    this.state = {
      name: "",
      email: "",
      phoneNumber: "",
      message: "",
      firstFocus: false,
      phoneFocus: false,
      emailFocus: false,
      messageFocus: false,
      submitted: false,
      showRecaptcha: false,
      page: "contact",
    };
  }

  onChangeFocus = (focusName, value) => {
    this.setState({ [focusName]: value });
  };

  onChangeValue = (e) => {
    this.setState({ [e.target.name]: e.target.value });
  };

  back = () => {
    this.setState({ page: "contact", errorText: "" });
  };

  onSubmit = (e) => {
    this.setState({ page: "recaptcha" });
  };

  sendMessage = (value) => {
    console.log("Captcha value:", value);

    if (!value || value == "") {
      this.setState({
        page: "error",
        errorText: "reCaptcha failed. Please try again.",
      });
    } else {
      var message = {
        name: this.state.name,
        email: this.state.email,
        phoneNumber: this.state.phoneNumber,
        message: this.state.message,
      };

      this.setState({ page: "success", errorText: "" });

      fetch("/api/messages", {
        method: "POST",
        body: JSON.stringify(message),
        headers: { "Content-Type": "application/json" },
      })
        .then(function (response) {
          return response.json();
        })
        .then(function (body) {
          console.log(body);
        });
    }
  };

  render() {
    return (
      <>
        <div
          className="section section-signup"
          style={{
            backgroundImage: this.props.backgroundImage,
            backgroundSize: "cover",
            backgroundPosition: "top center",
            minHeight: "700px",
          }}
        >
          {this.state.page === "success" ? (
            <ContactFeedback
              cardContent={
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    {"Thanks, " + this.state.name}
                  </CardTitle>
                  {"Our Attorneys will be in touch shortly."}
                </CardHeader>
              }
              back={this.back}
            />
          ) : this.state.page === "error" ? (
            <ContactFeedback
              cardContent={
                <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    {"Hm, there was a problem..."}
                  </CardTitle>
                  {this.state.errorText}
                </CardHeader>
              }
              back={this.back}
            />
          ) : this.state.page === "recaptcha" ? (
            <ContactFeedback
              cardContent={
                <CardHeader className="text-center">
                  <div style={{ display: "flex", justifyContent: "center" }}>
                    <ReCAPTCHA
                      sitekey="6LeAjAYaAAAAALScBj_NrbN1Gpq7ZZs_DnSzkQp9"
                      onChange={this.sendMessage}
                    />
                  </div>
                </CardHeader>
              }
              back={this.back}
            />
          ) : this.state.page === "contact" ? (
            <Container id="contactUs">
              <Row>
                <Card className="card-signup" data-background-color="blue">
                  <form
                    action=""
                    onSubmit={(e) => {
                      this.onSubmit();
                      e.preventDefault();
                    }}
                    className="form"
                  >
                    <CardHeader className="text-center">
                      <CardTitle className="title-up" tag="h3">
                        Contact Us
                      </CardTitle>
                      <p className="description">
                        Please provide us with a few details to get the ball
                        rolling.
                      </p>
                    </CardHeader>
                    <CardBody>
                      <InputGroup
                        className={
                          "no-border" +
                          (this.state.nameFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons users_circle-08"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <Input
                          id="name"
                          required
                          name="name"
                          onChange={this.onChangeValue}
                          value={this.state.name}
                          placeholder="Your Name..."
                          type="text"
                          onFocus={(e) => this.onChangeFocus("nameFocus", true)}
                          onBlur={(e) => this.onChangeFocus("nameFocus", false)}
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border" +
                          (this.state.emailFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i className="now-ui-icons ui-1_email-85"></i>
                          </InputGroupText>
                        </InputGroupAddon>
                        <Input
                          name="email"
                          value={this.state.email}
                          onChange={this.onChangeValue}
                          placeholder="Your Email..."
                          type="email"
                          required
                          onFocus={(e) =>
                            this.onChangeFocus("emailFocus", true)
                          }
                          onBlur={(e) =>
                            this.onChangeFocus("emailFocus", false)
                          }
                        ></Input>
                      </InputGroup>
                      <InputGroup
                        className={
                          "no-border" +
                          (this.state.phoneFocus ? " input-group-focus" : "")
                        }
                      >
                        <InputGroupAddon addonType="prepend">
                          <InputGroupText>
                            <i class="fas fa-phone"></i>
                          </InputGroupText>
                        </InputGroupAddon>

                        <MaskedFormControl
                          placeholder="Your Phone Number..."
                          onFocus={(e) =>
                            this.onChangeFocus("phoneFocus", true)
                          }
                          onBlur={(e) =>
                            this.onChangeFocus("phoneFocus", false)
                          }
                          type="text"
                          required
                          minLength={5}
                          value={this.state.phone}
                          onChange={this.onChangeValue}
                          name="phoneNumber"
                          mask="111-111-1111"
                          id="phone-number"
                        />
                      </InputGroup>
                      <div className="textarea-container">
                        <Input
                          id="message"
                          name="message"
                          minLength={5}
                          required
                          onChange={this.onChangeValue}
                          value={this.state.message}
                          onFocus={(e) =>
                            this.onChangeFocus("messageFocus", true)
                          }
                          onBlur={(e) =>
                            this.onChangeFocus("messageFocus", false)
                          }
                          cols="80"
                          placeholder="Type a message..."
                          rows="4"
                          type="textarea"
                        ></Input>
                      </div>
                    </CardBody>
                    <CardFooter
                      style={{
                        marginBottom: "0em",
                        marginTop: "0em",
                      }}
                      className="text-center"
                    >
                      <Input
                        style={{
                          width: "65%",
                          fontSize: "18px",
                          backgroundColor: "white",
                          color: "dodgerBlue",
                          textAlign: "center",
                          display: "inline-block",
                        }}
                        type="submit"
                        className="btn-neutral btn-round"
                        color="info"
                        value="Contact us"
                      />
                      <p
                        style={{
                          marginTop: "1em",
                          marginBottom: "0em",
                          fontSize: ".8em",
                        }}
                      >
                        The Law Offices of Avrum J. Rosen, PLLC is a debt relief
                        agency as such term is defined under the United States
                        Bankruptcy Code. Our law firm concentrates in bankruptcy
                        law and in foreclosure solutions.
                      </p>
                    </CardFooter>
                  </form>
                </Card>
              </Row>
            </Container>
          ) : (
            ""
          )}
        </div>
      </>
    );
  }
}

export default ContactUs;
