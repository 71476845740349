import React, { Component } from "react";
// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";

class Disclosures extends Component {
  state = {};
  render() {
    return (
      <>
        <div className="section section-about-us section-disclosures">
          <Row>
            <Col className="ml-auto mr-auto " md="8">
              <h2>
                Before our first meeting, please read the disclosures below and
                complete the following forms:
              </h2>{" "}
              {/*
              <span
                style={{ marginBottom: "5em", float: "left" }}
                className="pp-seperator-line"
              ></span>
              */}
            </Col>
          </Row>
          <Row style={{ marginTop: "3em" }}>
            <Col className="ml-auto mr-auto text-center " md="4">
              <ListGroup className="linkStyle">
                <ListGroupItem
                  tag="a"
                  target="_blank"
                  href="https://232d24ed-d912-4ece-b60f-4cc53f78b63e.filesusr.com/ugd/1265d4_e9d3f93739c64920af9d0432b799f7ce.pdf"
                >
                  Bankruptcy Information Sheet
                </ListGroupItem>
                <ListGroupItem
                  tag="a"
                  target="_blank"
                  href="https://232d24ed-d912-4ece-b60f-4cc53f78b63e.filesusr.com/ugd/1265d4_182a33e8c85c460f806f52bf80df8b1e.pdf"
                >
                  Pre-Filing Appointment Checklist
                </ListGroupItem>
              </ListGroup>
            </Col>
          </Row>
        </div>

        <div
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
          className="section section-tabs section-about-us section-story-overview"
        >
          <Container>
            <Row>
              <Col>
                <h2>DISCLOSURE PURSUANT TO 11 U.S.C. §527(a)(2)</h2>{" "}
                <span
                  style={{ marginBottom: "5em", float: "left" }}
                  className="pp-seperator-line"
                ></span>
              </Col>
            </Row>
            <div className="disclosures">
              <p>You are notified:</p>
              <p>
                All information that you are required to provide with a petition
                and thereafter during a case under the Bankruptcy Code is
                required to be complete, accurate, and truthful.
              </p>
              <p>
                All assets and all liabilities are required to be completely and
                accurately disclosed in the documents filed to commence the
                case. Some places in the Bankruptcy Code require that you list
                the replacement value of each assets. This must be the
                replacement value of the property at the date of filing the
                petition, without deducting for costs of sale or marketing,
                established after a reasonable inquiry. For property acquired
                for personal, family, or household use, replacement value means
                the price a retail merchant would charge for property of that
                kind, considering the age and condition of the property.
              </p>

              <p>
                The following information, which appear on Official Form 22,
                Statement of Current Monthly Income, are required to be stated
                after reasonable inquiry: current monthly income, the amounts
                specified in section 707(b)(2), and, in a case under chapter 13
                of the Bankruptcy Code, disposable income (determined in
                accordance with section 707(b)(2)).
              </p>
              <p>
                Information that you provide during your case may be audited
                pursuant to provisions of the Bankruptcy Code. Failure to
                provide such information may result in dismissal of the case
                under this title or other sanction, including criminal
                sanctions.
              </p>
            </div>
          </Container>
        </div>
        <div
          style={{ paddingTop: "5%", paddingBottom: "5%" }}
          className="section section-story-overview"
        >
          <Container>
            <Row>
              <Col>
                <h2>DISCLOSURES PURSUANT TO § 527</h2>{" "}
                <span
                  style={{ marginBottom: "5em", float: "left" }}
                  className="pp-seperator-line"
                ></span>
              </Col>
            </Row>
            <div className="disclosures">
              <p>
                IMPORTANT INFORMATION ABOUT BANKRUPTCY ASSISTANCE SERVICES FROM
                AN ATTORNEY OR BANKRUPTCY PETITION PREPARER
              </p>
              <p>
                If you decide to seek bankruptcy relief, your can represent
                yourself, you can hire an attorney to represent you, or your can
                get help in some localities from a bankruptcy petition prepared
                who is not an attorney. THE LAW REQUIRES AN ATTORNEY OR
                BANKRUPTCY PETITION PREPARER TO GIVE YOU A WRITTEN CONTRACT
                SPECIFYING WHAT THE ATTORNEY OR BANKRUPTCY PETITION PREPARER
                WILL DO FOR YOU AND HOW MUCH IT WILL COST. Ask to see the
                contract before you hire anyone.
              </p>
              <p>
                The following information helps you understand what must be done
                in a routine bankruptcy case to help you evaluate how much
                service you need. Although bankruptcy can be complex, many cases
                are routine.
              </p>
              <p>
                Before filing a bankruptcy case, either your or your attorney
                should analyze your eligibility for different forms of debt
                relief available under the Bankruptcy Code and which form of
                relief is most likely to be beneficial for you. Be sure you
                understand the relief you can obtain and its limitations. To
                file a bankruptcy case, documents called a Petition, Schedules,
                and Statement of Financial Affairs, as well as in some cases a
                Statement of Intention need to be prepared correctly and filed
                with the Bankruptcy Court. You will have to pay a filing fee to
                the Bankruptcy Court. Once your case starts, you will have to
                attend the required first meeting of creditors where you may be
                questioned by a court official called a "trustee" and by
                creditors.
              </p>
              <p>
                If you choose to file a chapter 7 case, you may be asked by a
                creditor to reaffirm a debt. You may want help deciding whether
                to do so. A creditor is not permitted to coerce you into
                reaffirming your debts.
              </p>

              <p>
                If you choose to file a chapter 13 case in which you repay your
                creditors what you can afford over 3 to 5 years, you may also
                want help with preparing your chapter 13 plan and with the
                confirmation hearing on your plan which will be before a
                bankruptcy judge.
              </p>
              <p>
                If you select another type of relief under the Bankruptcy Code
                other than chapter 7 or chapter 13, you will want to find out
                what should be done from someone familiar with that type of
                relief. Your bankruptcy case may also involve litigation. You
                are generally permitted to represent yourself in litigation in
                bankruptcy court, but only attorney, not bankruptcy petition
                preparers, can give you legal advice.
              </p>
              <p>
                (c) Except to the extent the debt relief agency provides the
                required information itself after reasonably diligent inquiry of
                the assisted person or others so as to obtain such information
                reasonably accurately for a debt relief agency providing
                bankruptcy assistance to an assisted person, to the extent
                permitted by non-bankruptcy law, shall provide each assisted
                person at the time required for the notice required under
                subsection (a)(1) reasonable sufficient information (which shall
                be provided in a clear and conspicuous writing) to the assisted
                person on how to provide all the information the assisted person
                is required to provide under this title pursuant to section 521,
                including -
              </p>
              <p>
                (1) how to value assets at replacement value, determine current
                monthly income, the amount specified in section 707(b)(2) and,
                in a chapter 13 how to determine disposable income in accordance
                with section 707(b)(2) and related calculations; (2) how to
                complete the list of creditors, including how to determine what
                amount is owed and what address for the creditor should be
                shown; and (3) how to determine what property is exempt and how
                to value exempt property at replacement value as defined in
                section 506.
              </p>
              <p>
                If you meet with an attorney at the firm for a bankruptcy
                consult, you will receive the following information: A list of
                approved credit counseling agencies. These agencies can assist
                you in obtaining a certificate of credit counseling. You cannot
                file for bankruptcy relief without that document. Simply contact
                one of the approved agencies and explain that you have met with
                a bankruptcy attorney and are considering filing. They will
                explain what you will need to do. In addition to the certificate
                of credit counseling, during the course of the bankruptcy case
                you will also need to obtain a financial management certificate.
                The agency will explain how to obtain that as well. Disclosures
                mandated by the Bankruptcy Code. Forms to complete that will
                allow us to complete a bankruptcy petition.
              </p>

              <p>
                In order to file your petition, you will need to supply the
                following items: The most recent six months of your pay stubs
                The most recent two years tax returns A copy of your driver’s
                license and social security card The most recent utility bill
                and mortgage statement (if applicable) An appraisal of your
                home’s value (if applicable) Copy of title for any vehicles you
                own The most recent six months of your bank statements
              </p>
              <p>
                The documents listed above are simply the initial required
                documents. You may be asked to provide additional documents. You
                will be advised at the time of filing if additional documents
                are necessary.
              </p>
              <p>
                Once you have prepared all of the documents, and have obtained
                all necessary pre-filing documents (credit counseling
                certificate), we will prepare the petition. You will also sign a
                retainer agreement that explains the costs and fees associated
                with filing, as well as the services included and excluded in
                your representation. All fees for our services must be paid
                prior to the filing of the petition.
              </p>
              <p>
                When the petition is ready to be filed, you will need to make an
                appointment to meet with your attorney in order to review the
                petition and sign it. It will then be filed with the Court. You
                will receive a case number as well as a copy of the notice of
                filing. Should any creditors contact you after filing, simply
                inform them that you have filed for bankruptcy and give them the
                case number. They cannot contact you once they have been
                informed that you have filed for bankruptcy relief.
              </p>
              <p>
                You will then receive a letter from this office that a petition
                has been filed on your behalf. The letter will give you the date
                for a section 341 meeting (meeting of creditors). You must
                appear at the 341 meeting. You must have your driver’s license
                and social security card with you for the trustee to review. If
                you do not have those items, the trustee will not examine you.
                The 341 meeting takes only 15 minutes or so. However, depending
                on the number of cases on at the time you appear, we may wait
                for some time to be examined. The letter will tell you the date,
                time and location of the meeting.
              </p>

              <p>
                If you have filed a chapter 13 case, in addition to a 341
                meeting, you may have to attend a confirmation hearing. You will
                be advised of the date, time and location of the confirmation
                hearing, as well as whether or not you will have to be present.
              </p>
              <p>
                Chapter 13 debtors must continue to make payments on all
                mortgages, car loans, and the like, after the filing. Failing to
                make post-petition payments will cause a secured creditor to
                move to vacate the automatic stay and continue their actions
                against you. In addition, chapter 13 debtors must make timely
                payments to the trustee. Failing to make trustee payments will
                cause the chapter 13 trustee to move to dismiss your case.
              </p>
              <p>
                This information does not include all information necessary for
                debtors. Please ask your attorney any specific questions about
                your specific case.
              </p>
            </div>
          </Container>
        </div>
      </>
    );
  }
}

export default Disclosures;
