import React, { Component } from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

import { HashLink as Link } from "react-router-hash-link";

class Team extends Component {
  state = {};
  render() {
    return (
      <div className="section section-team text-center">
        <Container fluid>
          <h2 className="title title-main">Our Awesome Team</h2>
          <span
            style={{ marginBottom: "5em" }}
            className="pp-seperator-line"
          ></span>
          <h5
            style={{ color: "black", marginBottom: "4em" }}
            className="description"
          >
            Learn more about our elite team of highly experienced, personable
            Attorneys.
          </h5>

          <div className="team">
            <Row>
              <Col>
                <Link className="lawyer-link" to="/our-team#avrum-rosen">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/avrum-scaled.png")}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "3px",
                        borderColor: "#2CA8FF",
                      }}
                    ></img>
                    <h4 style={{ paddingTop: "0px" }} className="title">
                      Avrum J. Rosen
                    </h4>
                    <p className="category text-info">Member</p>
                  </div>
                </Link>
              </Col>

              <Col>
                <Link className="lawyer-link" to="/our-team#deborah-dobbin">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/deborah-scaled.png")}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "3px",
                        borderColor: "#2CA8FF",
                        maxWidth: "200px !important",
                        width: "200px !important",
                        height: "200px !important",
                      }}
                    ></img>
                    <h4 style={{ paddingTop: "0px" }} className="title">
                      Deborah L. Dobbin
                    </h4>
                    <p className="category text-info">Associate</p>
                  </div>
                </Link>
              </Col>
              <Col>
                <Link className="lawyer-link" to="/our-team#nico-pizzo">
                  <div className="team-player">
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/nico-scaled.png")}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "3px",
                        borderColor: "#2CA8FF",
                      }}
                    ></img>
                    <h4 style={{ paddingTop: "0px" }} className="title">
                      Nico G. Pizzo
                    </h4>
                    <p className="category text-info">Associate</p>
                  </div>
                </Link>
              </Col>
              <Col>
                <Link className="lawyer-link" to="/our-team#alex-tsionis">
                  <div
                    //onClick={() => this.props.routeChange("/our-team#foo")}
                    className="team-player"
                  >
                    <img
                      alt="..."
                      className="rounded-circle img-fluid img-raised"
                      src={require("assets/img/alex-scaled.png")}
                      style={{
                        borderStyle: "solid",
                        borderWidth: "3px",
                        borderColor: "#2CA8FF",
                      }}
                    ></img>
                    <h4 style={{ paddingTop: "0px" }} className="title">
                      Alex E. Tsionis
                    </h4>
                    <p className="category text-info">Associate</p>
                  </div>
                </Link>
              </Col>
            </Row>
            <Row style={{ marginTop: "3em" }}>
              <Col className="text-center ml-auto mr-auto" lg="4" md="4">
                <div className="send-button">
                  <Button
                    block
                    className="btn-round"
                    color="info"
                    onClick={() => this.props.routeChange("/our-team")}
                    size="lg"
                  >
                    Meet Our Team
                  </Button>
                </div>
              </Col>
            </Row>
          </div>
        </Container>
      </div>
    );
  }
}

export default Team;
