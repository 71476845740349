import React from "react";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";
import Specialty from "views/index-sections/Specialty";
import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import SvgContainer from "views/index-sections/SvgContainer";
import FieldExperts from "views/index-sections/FieldExperts";

function AreasOfExpertise() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });
  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          headerTitle={"Areas of Expertise"}
          headerImage={"url(" + require("assets/img/justiceDark.png") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
        />{" "}
        <FieldExperts />
        <SvgContainer
          className="specialties-section"
          //initialHeight={"140vh"}
          svgTop={"url(" + require("assets/img/whiteVector5.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/libraryBackground.png") + ")"
          }
          sectionContent={<Specialty />}
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={"url(" + require("assets/img/bg13.png") + ")"}
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default AreasOfExpertise;
