import React, { Component } from "react";
// Import reactstrap components
import { Button, Container, Row, Col } from "reactstrap";
import NewsCard from "./NewsCard";

class PressSection extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    return (
      <div>
        <Container>
          <div>
            <Row>
              <Col style={{ marginBottom: "1em" }} md="4">
                <NewsCard src={this.props.image} />
              </Col>
              <Col className="pressText" md="8">
                <h4 style={{ textAlign: "left" }}>{this.props.title}</h4>
                <h5 style={{ textAlign: "left", fontSize: "1em" }}>
                  {"Source: " + this.props.source}
                </h5>
                <h5 style={{ textAlign: "left", fontSize: "1em" }}>
                  {"Published on: " + this.props.date}
                </h5>
                <div className="send-button" style={{ width: "60%" }}>
                  <Button
                    style={{ borderRadius: "15px" }}
                    block
                    //   className="btn-round"
                    color="info"
                    onClick={() => window.open(this.props.href)}
                    size="lg"
                  >
                    Read More
                  </Button>
                </div>{" "}
              </Col>
            </Row>
            <hr style={{ marginTop: "3em" }} />
          </div>
        </Container>
      </div>
    );
  }
}

export default PressSection;
