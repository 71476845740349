import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col
} from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import LandingPageHeader from "components/Headers/LandingPageHeader.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import SliderComponent from "components/Misc/SliderComponent.jsx";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";
import Specialties from "views/index-sections/Specialties";
import SvgContainer from "views/index-sections/SvgContainer";

import Testimonials from "views/index-sections/Testimonials";
import Team from "views/index-sections/Team";
import About from "views/index-sections/About";
import CovidAlert from "./CovidAlert";
import CovidModal from "./CovidModal";

function LandingPage(props) {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  const routeChange = path => {
    window.scrollTo(0, 0);
    props.history.push(path);
  };

  return (
    <>
      <CovidModal />
      <ExamplesNavbar />
      <div className="wrapper">
        <LandingPageHeader
          svgBottom={"url(" + require("assets/img/whiteVector2.svg") + ")"}
        />
        <About routeChange={routeChange} />
        <SvgContainer
          svgTop={"url(" + require("assets/img/topWhiteVector.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector2.svg") + ")"}
          backgroundImage={
            "url(" + require("assets/img/grayArchitecture.png") + ")"
          }
          sectionContent={<Specialties routeChange={routeChange} />}
        />
        <Team routeChange={routeChange} />
        <SvgContainer
          svgTop={"url(" + require("assets/img/whiteVector3.svg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector2.svg") + ")"}
          backgroundImage={"url(" + require("assets/img/writingPen.png") + ")"}
          sectionContent={<Testimonials />}
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={"url(" + require("assets/img/bg13.png") + ")"}
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default LandingPage;
