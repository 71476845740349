import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";

import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import { articles } from "../../components/Misc/Articles";
import SvgContainer from "views/index-sections/SvgContainer";
import GoogleMapsSection from "views/index-sections/GoogleMapsSection";

function Contact() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <SvgContainer
          className="google-maps-section"
          svgBottom={"url(" + require("assets/img/whiteVector4.svg") + ")"}
          backgroundImage={"url(" + require("assets/img/contactUsBg.png") + ")"}
          sectionContent={
            <GoogleMapsSection
              header={
                <h1
                  style={{ marginBottom: "2em", color: "white" }}
                  className="title "
                >
                  Contact Us
                </h1>
              }
            />
          }
        />
        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/aboutUsBottom.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default Contact;
