import PressSection from "../../views/index-sections/PressSection";
import React, { Component } from "react";

/**
 * The array of articles
 */
const bloombergArticle = (
  <PressSection
    title="New Bankruptcy Law Could Save Small Businesses Slammed by Virus"
    source="Bloomberg Law"
    date="April 15, 2020, 2:46 AM"
    image={require("assets/img/article1.jpg")}
    href="https://news.bloomberglaw.com/bankruptcy-law/new-bankruptcy-law-could-save-small-businesses-slammed-by-virus"
  />
);
export const articles = [bloombergArticle];
