import React, { Component } from "react";
import StarRatings from "react-star-ratings";

class SliderComponent extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <div className="team-player">
        <h4 className="title">{this.props.headerText}</h4>
        <p className="category text-info">{this.props.subText}</p>
        <StarRatings
          rating={this.props.rating}
          starDimension="1.5em"
          starRatedColor="#2CA8FF"
          numberOfStars={5}
          name="rating"
        />
        <p style={{ marginTop: "2em", color: "black" }}>
          {this.props.description}
        </p>
      </div>
    );
  }
}

export default SliderComponent;
/*`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places*/
