import PressSection from "../../views/index-sections/PressSection";
import React, { Component } from "react";
import AuctionSection from "./AuctionSection";

/**
 * The array of articles
 */
const auctions = (
  <AuctionSection
    title={`Richard L. Stern, the chapter 7 trustee (the "Trustee") of the Estate of Ronald S. Merrow and Tina L. Merrow, the debtors (the "Debtors"), shall conduct an Auction Sale (the "Auction") via Zoom`}
    noticeOfAuction={
      "/downloads/notice-of-auction-jan-6-2021.pdf"
      //   <a
      //     className="clean-link"
      //     href="/downloads/notice-of-auction-jan-6-2021.pdf"
      //     download
      //     style={{
      //       width: "260px",
      //       height: "100%",
      //       textAlign: "center",
      //       display: "inline-block",
      //       position: "relative",
      //       padding: "2em",
      //       margin: "-2em",
      //     }}
      //   >
      //     Notice of Auction
      //     <i style={{ marginLeft: "1em" }} class="fas fa-download"></i>
      //   </a>
    }
    termsOfSale={
      "/downloads/terms-of-sale-jan-6-2021.pdf"
      //   <a
      //     className="clean-link"
      //     href="/downloads/terms-of-sale-jan-6-2021.pdf"
      //     download
      //     style={{
      //       display: "block",
      //       width: "260px",
      //       height: "100%",
      //       textAlign: "center",
      //     }}
      //   >
      //     Terms of Sale
      //     <i style={{ marginLeft: "1em" }} class="fas fa-download"></i>
      //   </a>
    }
    date="January 6, 2021, 12:00 PM EST"
  />
);
export const auctionData = [auctions];
