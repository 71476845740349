import React from "react";
import ReactDOM from "react-dom";
import { BrowserRouter, Route, Switch, Redirect } from "react-router-dom";

// styles for this kit
import "assets/css/bootstrap.min.css";
import "assets/scss/now-ui-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/nucleo-icons-page-styles.css";
// pages for this kit
import NucleoIcons from "views/NucleoIcons.js";
import LandingPage from "views/examples/LandingPage.js";
import OurTeam from "views/examples/OurTeam";
import AreasOfExpertise from "views/examples/AreasOfExpertise";
import ReportedDecisions from "views/examples/ReportedDecisions";
import CurrentClients from "views/examples/CurrentClients";
import ProspectiveClients from "views/examples/ProspectiveClients";
import AboutOurFirm from "views/examples/AboutOurFirm";
import Press from "views/examples/Press";
import ContactUs from "views/index-sections/ContactUs";
import Contact from "views/examples/Contact";
import Auctions from "views/examples/Auctions";

ReactDOM.render(
  <BrowserRouter>
    <Switch>
      <Switch>
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />
        <Route
          path="/about-our-firm"
          render={(props) => <AboutOurFirm {...props} />}
        />
        <Route path="/our-team" render={(props) => <OurTeam {...props} />} />
        <Route
          path="/areas-of-expertise"
          render={(props) => <AreasOfExpertise {...props} />}
        />
        <Route
          path="/reported-decisions"
          render={(props) => <ReportedDecisions {...props} />}
        />
        <Route path="/press" render={(props) => <Press {...props} />} />
        <Route
          path="/current-clients"
          render={(props) => <CurrentClients {...props} />}
        />
        <Route
          path="/prospective-clients"
          render={(props) => <ProspectiveClients {...props} />}
        />
        <Route path="/auctions" render={(props) => <Auctions {...props} />} />
        <Route path="/contact-us" render={(props) => <Contact {...props} />} />
        <Route
          path="/nucleo-icons"
          render={(props) => <NucleoIcons {...props} />}
        />
        <Route
          path="/landing-page"
          render={(props) => <LandingPage {...props} />}
        />

        <Redirect to="/landing-page" />
        <Redirect from="/" to="/landing-page" />
      </Switch>
    </Switch>
  </BrowserRouter>,
  document.getElementById("root")
);
