import React from "react";

// reactstrap components
import {
  Button,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components

function ProfilePageHeader(props) {
  let pageHeader = React.createRef();

  React.useEffect(() => {
    if (window.innerWidth > 991) {
      const updateScroll = () => {
        let windowScrollTop = window.pageYOffset / 3;
        pageHeader.current.style.transform =
          "translate3d(0," + windowScrollTop + "px,0)";
      };
      window.addEventListener("scroll", updateScroll);
      return function cleanup() {
        window.removeEventListener("scroll", updateScroll);
      };
    }
  });
  return (
    <>
      <div className="page-header clear-filter page-header-small">
        <div
          className="page-header-image"
          style={{
            backgroundImage: props.headerImage,
            filter: `brightness(${props.brightness ? props.brightness : 1.0})`,
          }}
          ref={pageHeader}
        ></div>
        {props.svgBottom ? (
          <div
            className="svgBottom"
            style={{
              background: props.svgBottom,
              backgroundRepeat: "no-repeat",
              backgroundPosition: "bottom",
            }}
          />
        ) : null}
        <div className="content-center">
          <Container style={{ paddingTop: "1em" }}>
            <h1 className="title">{props.headerTitle}</h1>
          </Container>
        </div>
      </div>
    </>
  );
}

export default ProfilePageHeader;
