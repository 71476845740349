import React from "react";
import Slider from "react-slick";
// Import css files
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components
import ExamplesNavbar from "components/Navbars/ExamplesNavbar.js";
import DefaultFooter from "components/Footers/DefaultFooter.js";
import HereToHelp from "views/index-sections/HereToHelp.jsx";
import ContactUs from "views/index-sections/ContactUs";

import ProfilePageHeader from "components/Headers/ProfilePageHeader";
import { auctionData } from "../../components/Misc/AuctionData";

function Auctions() {
  const [firstFocus, setFirstFocus] = React.useState(false);
  const [lastFocus, setLastFocus] = React.useState(false);
  React.useEffect(() => {
    window.scrollTo(0, 0);

    document.body.classList.add("landing-page");
    document.body.classList.add("sidebar-collapse");
    document.documentElement.classList.remove("nav-open");
    return function cleanup() {
      document.body.classList.remove("landing-page");
      document.body.classList.remove("sidebar-collapse");
    };
  });

  return (
    <>
      <ExamplesNavbar />
      <div className="wrapper">
        <ProfilePageHeader
          brightness={0.7}
          headerTitle={"Auctions"}
          headerImage={"url(" + require("assets/img/auction.jpeg") + ")"}
          svgBottom={"url(" + require("assets/img/whiteVector7.svg") + ")"}
        />

        <div className="section pressSection" style={{ marginBottom: "22em" }}>
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="7">
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <h3
                    style={{ marginLeft: "0px" }}
                    className="title title-main required-field"
                  >
                    {" "}
                    Please download the Notice of Auction and Terms of Sale to
                    participate
                  </h3>
                </div>

                <span
                  style={{ marginBottom: "4em" }}
                  className="pp-seperator-line"
                ></span>
              </Col>
            </Row>
          </Container>
          {auctionData}
        </div>

        <HereToHelp />
        <ContactUs
          backgroundImage={
            "url(" + require("assets/img/aboutUsBottom.png") + ")"
          }
        />
        <DefaultFooter />
      </div>
    </>
  );
}

export default Auctions;
