import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class KnowlegeIsPower extends Component {
  state = {};
  render() {
    return (
      <div className="section section-about-us">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
              <h2 className="title title-main">Knowledge is Power</h2>
              <span
                style={{ marginBottom: "5em" }}
                className="pp-seperator-line"
              ></span>
              <h5 style={{ color: "black" }} className="description">
                We firmly believe that knowledge is power, and want to help our
                clients make informed and impactful decisions. That is why we
                dedicate ourselves to providing expert guidance.
              </h5>
            </Col>
          </Row>
        </Container>
      </div>
    );
  }
}

export default KnowlegeIsPower;
