import React, { Component } from "react";

import {
  Button,
  Card,
  CardHeader,
  CardBody,
  CardFooter,
  CardTitle,
  Form,
  Input,
  InputGroupAddon,
  InputGroupText,
  InputGroup,
  Container,
  Row,
} from "reactstrap";

class ContactFeedback extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <>
        <Container id="contactUs">
          <Row>
            <Card className="card-signup" data-background-color="blue">
              <Form action="" className="form" method="">
                {/* <CardHeader className="text-center">
                  <CardTitle className="title-up" tag="h3">
                    {this.props.header}
                  </CardTitle>
                  {this.props.subHeader}
                </CardHeader> */}
                {this.props.cardContent}
                <CardFooter
                  style={{ marginBottom: "0em", marginTop: "0em" }}
                  className="text-center"
                >
                  <Button
                    className="btn-neutral btn-round"
                    color="info"
                    onClick={this.props.back}
                    size="lg"
                  >
                    Back
                  </Button>
                  <p
                    style={{
                      marginTop: "1em",
                      marginBottom: "0em",
                      fontSize: ".8em",
                    }}
                  >
                    The Law Offices of Avrum J. Rosen, PLLC is a debt relief
                    agency as such term is defined under the United States
                    Bankruptcy Code. Our law firm concentrates in bankruptcy law
                    and in foreclosure solutions.
                  </p>
                </CardFooter>
              </Form>
            </Card>
          </Row>
        </Container>
      </>
    );
  }
}

export default ContactFeedback;
