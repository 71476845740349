import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Collapse,
  NavbarBrand,
  Navbar,
  NavItem,
  NavLink,
  Nav,
  Row,
  Container,
  UncontrolledTooltip,
} from "reactstrap";

function ExamplesNavbar() {
  const [navbarColor, setNavbarColor] = React.useState("navbar-transparent");
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    const updateNavbarColor = () => {
      if (
        document.documentElement.scrollTop > 10 ||
        document.body.scrollTop > 10
      ) {
        setNavbarColor("");
      } else if (
        document.documentElement.scrollTop < 10 ||
        document.body.scrollTop < 10
      ) {
        setNavbarColor("navbar-transparent");
      }
    };
    window.addEventListener("scroll", updateNavbarColor);
    return function cleanup() {
      window.removeEventListener("scroll", updateNavbarColor);
    };
  });
  return (
    <>
      {collapseOpen ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar
        id="navbar-rkd"
        className={"fixed-top " + navbarColor}
        color="info"
        expand="md"
      >
        <Container fluid>
          <div className="navbar-translate">
            <div style={{ marginLeft: "1em" }}>
              <NavbarBrand to="/home" tag={Link} style={{ fontWeight: 700 }}>
                <Row>
                  {" "}
                  <span className="rkd">
                    The Law Offices of Avrum J. Rosen, PLLC
                  </span>
                </Row>
              </NavbarBrand>
              <Row>
                {" "}
                <a style={{ color: "white" }} href="tel:+1-631-423-8527">
                  {" "}
                  <span style={{ fontSize: "16px", fontWeight: "700" }}>
                    631-423-8527
                  </span>
                </a>
              </Row>
            </div>

            <button
              className="navbar-toggler navbar-toggler"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setCollapseOpen(!collapseOpen);
              }}
              aria-expanded={collapseOpen}
              type="button"
            >
              <span className="navbar-toggler-bar top-bar"></span>
              <span className="navbar-toggler-bar middle-bar"></span>
              <span className="navbar-toggler-bar bottom-bar"></span>
            </button>
          </div>
          <Collapse
            className="justify-content-end"
            isOpen={collapseOpen}
            navbar
          >
            <Nav navbar style={{ fontSize: "18px", fontWeight: 600 }}>
              <NavItem id="about">
                <NavLink to="/about-our-firm" tag={Link}>
                  About Us
                </NavLink>
              </NavItem>

              <NavItem id="team">
                <NavLink to="/our-team" tag={Link}>
                  Our Team
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id="expertise" tag={Link} to="/areas-of-expertise">
                  Areas Of Expertise
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="reportedDecisions"
                  tag={Link}
                  to="/reported-decisions"
                >
                  Reported Decisions
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id="press" tag={Link} to="/press">
                  Press
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink id="currentClients" tag={Link} to="/current-clients">
                  Current Clients
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink
                  id="prospectiveClients"
                  tag={Link}
                  to="/prospective-clients"
                >
                  Prospective Clients
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id="auctions" tag={Link} to="/auctions">
                  Auctions
                </NavLink>
              </NavItem>

              <NavItem>
                <NavLink id="contact-us" tag={Link} to="/contact-us">
                  Contact Us
                </NavLink>
              </NavItem>

              {/* <NavItem>
                <NavLink href="" target="_blank" id="facebook-tooltip">
                  <i className="fab fa-facebook-square"></i>
                  <p className="d-lg-none d-xl-none">Facebook</p>
                </NavLink>
                <UncontrolledTooltip target="#facebook-tooltip">
                  Like us on Facebook
                </UncontrolledTooltip>
              </NavItem>
              <NavItem>
                <NavLink href="" target="_blank" id="linkedin-tooltip">
                  <i className="fab fa-linkedin"></i>
                  <p className="d-lg-none d-xl-none">Linkedin</p>
                </NavLink>
                <UncontrolledTooltip target="linkedin-tooltip">
                  Connect with us on Linkedin
                </UncontrolledTooltip>
              </NavItem> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default ExamplesNavbar;
