import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class ServingNewYork extends Component {
  state = {};
  render() {
    return (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title title-main">
              Serving New York For Over 30 Years{" "}
            </h2>
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
            <h5 style={{ color: "black" }} className="description">
              We have extensive experience representing all parties in all
              aspects of bankruptcy.
            </h5>
          </Col>
        </Row>
        <div className="section-story-overview">
          <Row style={{ justifyContent: "center" }}>
            <Col md="6">
              <div
                className="image-container image-left imagePadding"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/longIslandEast.png") + ")"
                }}
              ></div>
            </Col>
            <Col md="6">
              <div
                className="image-container image-right"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/manhattanSunset.jpg") + ")"
                }}
              ></div>
            </Col>
          </Row>
        </div>
      </Container>
    );
  }
}

export default ServingNewYork;
