import React, { Component } from "react";
// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col,
} from "reactstrap";
class Specialty extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <>
        <Container id={"areas-of-expertise"} style={{ marginTop: "4em" }}>
          <Row>
            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader>
                  <h2 className="title title-main">Chapter 7 Bankruptcy</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Chapter 7 allows individuals to discharge most consumer debt
                    and other debt, including deficiencies from mortgage
                    foreclosures, to obtain a fresh start to rebuild their lives
                    and their credit.
                  </h5>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    The Law Offices of Avrum J. Rosen, PLLC has extensive
                    experience handling Chapter 7 filings for complex financial
                    situations. These cases are often referred in by other
                    bankruptcy firms.
                  </h5>
                </CardBody>
              </Card>
            </Col>
            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader className="text-center">
                  <h2 className="title title-main">Chapter 13 Bankruptcy</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Chapter 13 allows homeowners who have fallen behind on their
                    mortgage payments or other debt to restructure that debt and
                    save their homes and other assets.
                  </h5>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    The Law Offices of Avrum J. Rosen, PLLC, has succesfully
                    helped several New York homeowners protect their assets.
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader className="text-center">
                  <h2 className="title title-main">Chapter 11 Bankruptcy</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Chapter 11 is designed to help save businesses of all kinds
                    from overwhelming debt to allow them to either reorganize,
                    sell their assets, or liquidate in an orderly fashion.
                  </h5>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Chapter 11 is also available for certain individuals who do
                    not qualify to file Chapter 13 in order to reorganize and
                    save their homes and other assets. The Law Offices of Avrum
                    J. Rosen, PLLC has successfully handled restructurings for
                    businesses involved in healthcare, media, manufacturing,
                    real estate, and service industries.
                  </h5>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader className="text-center">
                  <h2 className="title title-main">Bankruptcy Litigation</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Our litigation practice focuses on complex bankruptcy
                    matters. The attorneys at The Law Offices of Avrum J. Rosen,
                    PLLC have decades of experience representing Trustees and
                    all types of interested parties in bankruptcy litigation.
                  </h5>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    Our knowledge and experience delivers skilled and aggressive
                    representation often resulting in victory on the merits or a
                    favorable settlement without ever having to go to trial.
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>

          <Row>
            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader>
                  <h2 className="title title-main">Creditors' Rights</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    A Creditors' Rights attorney protects the right of creditors
                    to collect debts from individuals or businesses. This
                    attorney can also settle disputes between two or more
                    creditors trying to collect money from the same person.
                  </h5>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    If you are owed any money, a creditors rights attorney can
                    help you decide the best course of action to get your money
                    back.
                  </h5>
                </CardBody>
              </Card>
            </Col>

            <Col lg="6" md="12" sm="12">
              <Card className="specialties-card">
                <CardHeader>
                  <h2 className="title title-main">Real Estate Law</h2>
                  <span
                    style={{ marginBottom: "5em" }}
                    className="pp-seperator-line"
                  ></span>
                </CardHeader>
                <CardBody>
                  <h5 style={{ textAlign: "left", color: "black" }}>
                    The Law Offices of Avrum J. Rosen, PLLC, handles sales and
                    purchases of residential and commercial real estate in the
                    New York Metro Area. We have handled transactions from
                    simple home closings to transactions worth over $50 million.
                    We have also negotiated out-of-court restructurings of
                    commercial mortgages.
                  </h5>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </Container>
      </>
    );
  }
}

export default Specialty;
