import React, { Component } from "react";
// reactstrap components
import {
  Card,
  Button,
  CardImg,
  CardTitle,
  CardText,
  CardGroup,
  CardSubtitle,
  CardBody
} from "reactstrap";

class NewsCard extends Component {
  state = {};
  render() {
    return (
      <div
        className=""
        style={{
          padding: ".5em",
          margin: "0px",
          backgroundColor: "#cfcfcf"
        }}
      >
        <Card style={{ marginBottom: "0px", padding: "1em" }}>
          <CardImg top width="100%" src={this.props.src} alt="Card image cap" />
        </Card>
      </div>
    );
  }
}

export default NewsCard;
