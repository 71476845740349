import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";
class DecisionCard extends Component {
  constructor() {
    super();
    this.state = {};
  }
  render() {
    return (
      <Card style={{ height: "95%" }}>
        <CardBody style={{ height: "100%", textAlign: "left", padding: "2em" }}>
          <h4>Case</h4>
          <hr style={{ textAlign: "center", width: "100%" }} />

          <p className="reported-decision-md">{this.props.case}</p>
          <h4>Court</h4>
          <hr style={{ textAlign: "center", width: "100%" }} />

          <p className="reported-decision-sm">{this.props.court}</p>
          <h4>Argued</h4>
          <hr style={{ textAlign: "center", width: "100%" }} />

          <p className="reported-decision-xs">{this.props.argued}</p>
          <h4>Decided</h4>
          <hr style={{ textAlign: "center", width: "100%" }} />

          <p className="reported-decision-xs">{this.props.decided}</p>
          <div className="send-button">
            <Button
              block
              className="btn-round"
              color="info"
              onClick={() => window.open(this.props.link)}
              size="lg"
            >
              More Details
            </Button>
          </div>
        </CardBody>
      </Card>
    );
  }
}

export default DecisionCard;
