import React, { Component } from "react";
import {
  Card,
  CardHeader,
  CardBody,
  Button,
  NavItem,
  NavLink,
  Nav,
  TabContent,
  TabPane,
  Container,
  Row,
  Col
} from "reactstrap";

class SvgContainer extends Component {
  constructor(props) {
    super();
    this.state = {};
  }
  render() {
    return (
      <>
        <div
          style={{
            height: this.props.initialHeight
              ? this.props.initialHeight
              : "auto",
            minHeight: this.props.minHeight ? this.props.minHeight : "90vh",
            maxHeight: "300vh",
            color: "black"
          }}
          className={`page-header clear-filter ${this.props.className}`}
        >
          <div
            className="page-header-image"
            style={{
              backgroundImage: this.props.backgroundImage
            }}
          ></div>
          {this.props.svgBottom ? (
            <div
              className="svgTop"
              style={{
                background: this.props.svgTop,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "top"
              }}
            />
          ) : null}
          {this.props.svgBottom ? (
            <div
              className="svgBottom"
              style={{
                background: this.props.svgBottom,
                backgroundRepeat: "no-repeat",
                backgroundPosition: "bottom"
              }}
            />
          ) : null}

          {this.props.sectionContent}
        </div>
      </>
    );
  }
}

export default SvgContainer;
