import {
  withScriptjs,
  withGoogleMap,
  GoogleMap,
  Marker,
} from "react-google-maps";

import MarkerWithLabel from "react-google-maps/lib/components/addons/MarkerWithLabel";

import React, { Component } from "react";
class GoogleMaps extends Component {
  constructor() {
    super();
    this.state = {};
  }

  render() {
    const MapWithAMarker = withScriptjs(
      withGoogleMap((props) => (
        <GoogleMap
          defaultZoom={18}
          defaultCenter={{ lat: 40.869752, lng: -73.426964 }}
        >
          {/*<Marker position={{ lat: 40.8697429, lng: -73.4291529 }} />*/}
          <MarkerWithLabel
            position={{ lat: 40.869752, lng: -73.426964 }}
            labelAnchor={new window.google.maps.Point(230, 100)}
            labelStyle={{
              backgroundColor: "white",
              fontSize: "1.5em",
              padding: ".5em",
            }}
          >
            <div>
              The Law Offices of Avrum J. Rosen, PLLC 38 New Street Huntington,
              NY 11743
            </div>
          </MarkerWithLabel>
        </GoogleMap>
      ))
    );

    const API_KEY = "AIzaSyDmlFrSNcRjbD1OVUfSvFjCwrgwqJeqTv4";

    return (
      <MapWithAMarker
        googleMapURL={`https://maps.googleapis.com/maps/api/js?key=${API_KEY}&v=3.exp&libraries=geometry,drawing,places`}
        loadingElement={<div style={{ height: `100%` }} />}
        containerElement={<div style={{ height: `30em` }} />}
        mapElement={<div style={{ height: `100%` }} />}
      />
    );
  }
}

export default GoogleMaps;
