import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";

class OurClients extends Component {
  state = {};
  render() {
    return (
      <Row className="clients-row">
        <Col md="6">
          <div className="clients-padding">
            <h2 className="title title-main">Chapter 11 Clients</h2>{" "}
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
            <p>
              If you are currently a debtor in Chapter 11, you can click on the
              links below for sample monthly operating report forms, guideliness
              for Chapter 11 debtors prepared by the Office of the United States
              Trustee, and other important information.
            </p>
          </div>
        </Col>
        <Col md="6">
          <div className="clients-padding">
            <h2 className="title title-main">Chapter 13 Clients</h2>{" "}
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
            <p>
              If you are curently a debtor in Chapter 13 and your Chapter 13
              trustee is Marianne DeRosa, please send your monthly plan
              payments, in the form of a Money Order, Certified Check or Bank
              Check only, with your case number written on the face of the
              document to: Marianne DeRosa, PO Box 2178, Memphis, TN 38101-2178.
              Personal checks will not be accepted.
            </p>
            <p>
              If your Chapter 13 trustee is Michael J. Macco, please send your
              monthly plan payments, in the form of a Money Order, Certified
              Check or Bank Check only, with your case number written on the
              face of the document to: Michael J. Macco, Macco & Stern LLP, 135
              Pinelawn Road, Suite 120 South, Melville, NY 11747. Personal
              checks will not be accepted.
            </p>
          </div>
        </Col>
      </Row>
    );
  }
}

export default OurClients;
