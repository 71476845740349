import React, { Component } from "react";
import { Container, Row, Col } from "reactstrap";
import { ListGroup, ListGroupItem } from "reactstrap";

class UsefulLinks extends Component {
  state = {};
  render() {
    return (
      <Container>
        <Row>
          <Col className="ml-auto mr-auto text-center" md="8">
            <h2 className="title title-main">Useful Links</h2>
            <span
              style={{ marginBottom: "5em" }}
              className="pp-seperator-line"
            ></span>
          </Col>
          <Col className="ml-auto mr-auto text-center" md="8">
            <ListGroup className="linkStyle">
              <ListGroupItem
                tag="a"
                href="https://232d24ed-d912-4ece-b60f-4cc53f78b63e.filesusr.com/ugd/1265d4_49e44d2e330b45f8ab21a13df0621040.xls?dn=Corporate_MOR.xls"
                target="_blank"
                data-type="document"
              >
                Sample Monthly Operating Report
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="https://232d24ed-d912-4ece-b60f-4cc53f78b63e.filesusr.com/ugd/1265d4_8621f1bdab23495b80b9572fd3ac8546.pdf"
                target="_blank"
              >
                Small Business Monthly Operating Report
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.justice.gov/ust/r20/docs/general/ch11_guidelines.pdf"
                target="_blank"
              >
                Operating Guidelines and Reporting Requireents for Debtors in
                Possession and Trustees
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.usdoj.gov/ust/r02/central_islip.htm"
                target="_blank"
              >
                The Office of the United States Trustee, Eastern District
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.greenpathbk.com"
                target="_blank"
              >
                Greenpath Credit Counseling
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.annualcreditreport.com"
                target="_blank"
              >
                Annual Free Credit Reports
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.nyeb.uscourts.gov"
                target="_blank"
              >
                United States Bankruptcy Court, Eastern District of New York
              </ListGroupItem>
              <ListGroupItem
                tag="a"
                href="http://www.nysb.uscourts.gov"
                target="_blank"
              >
                United States Bankruptcy Court, Southern District of New York
              </ListGroupItem>
            </ListGroup>
          </Col>
        </Row>
      </Container>
    );
  }
}

export default UsefulLinks;
